'use client';
import { useState, createContext, ReactNode, useEffect } from 'react';
import { Connection, PublicKey, clusterApiUrl } from '@solana/web3.js';

declare global {
  interface Window {
    solana: any;
  }
}
const isBrowser = typeof window !== 'undefined';
const WalletContext = createContext<any>([]);

const WalletProvider = ({ children }: { children: ReactNode }) => {
  const [walletAddress, setWalletAddress] = useState<string | null>(null);

  const checkIfWalletIsConnected = async () => {
    try {
      if (isBrowser) {
        const { solana } = window;
        if (solana) {
          if (solana.isPhantom) {
            console.log('Phantom wallet found!');
            const response = await solana.connect({ onlyIfTrusted: true });
            console.log(
              'Connected with public key:',
              response.publicKey.toString(),
            );
            setWalletAddress(response.publicKey.toString());
          }
        } else {
          alert('Solana object not found! Get a Phantom wallet');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isBrowser) {
      const onLoad = async () => {
        await checkIfWalletIsConnected();
      };
      window.addEventListener('load', onLoad);

      return () => window.removeEventListener('load', onLoad);
    }
  }, []);

  return (
    <WalletContext.Provider value={[walletAddress, setWalletAddress]}>
      {children}
    </WalletContext.Provider>
  );
};

export { WalletContext, WalletProvider };
