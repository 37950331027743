import React from 'react';
import cn from 'classnames';
import { NewsItem } from '@app/components';
import { SidebarProps } from './Sidebar.props';
import styles from './Sidebar.module.css';

const Sidebar = ({ className, ...props }: SidebarProps) => {
  return (
    <aside className={cn(styles.sidebar, className)} {...props}>
      <h2 className={cn(styles.sidebar_title)}>Nft Collections</h2>
      <NewsItem
        title="Fantasy AI NFT Collection"
        content="Fantasy AI NFT Collection."
        url="https://magiceden.io/marketplace/fanc"
      />
      <h2 className={cn(styles.sidebar_title)}>Links</h2>
      <NewsItem
        title="NFT Market"
        content="The NFT marketplace. Nft news and reviews."
        url="https://marchcat.com/"
      />
      <NewsItem
        title="News cryptocurrency DeFi"
        content="News cryptocurrency DeFi."
        url="https://newscryptodefi.com/"
      />
    </aside>
  );
};

export default Sidebar;
