'use client';
import { useState, useContext } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import {
  ApiOutlined,
  LogoutOutlined,
  PoundCircleOutlined,
  SettingOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { AuthContext } from '@app/context/auth';
import { ConnectWallet } from '@app/components';
import styles from './TopNav.module.css';

const TopNav = ({ className }: any) => {
  const [current, setCurrent] = useState('mail');
  const [auth, setAuth] = useContext(AuthContext);
  const router = useRouter();

  const signOut = () => {
    localStorage.removeItem('auth');
    setAuth({
      user: null,
      token: '',
    });
    router.push('/signin');
  };

  const onClick = (e: any) => {
    setCurrent(e.key);
  };

  const roleBasedLink = () => {
    if (auth?.user?.role === 'Admin') {
      return '/admin';
    } else if (auth?.user?.role === 'Author') {
      return '/author';
    } else {
      return '/subscriber';
    }
  };

  const items = [
    {
      label: <Link href="/">NCDO</Link>,
      key: 'mail',
      icon: <PoundCircleOutlined />,
    },
    {
      label: '',
      key: 'divider',
      disabled: true,
    },
    {
      label: auth?.user == null && <Link href="/signup/">Signup</Link>,
      key: 'signup',
      icon: auth?.user == null && <UserAddOutlined />,
    },
    {
      label: auth?.user == null && <Link href="/signin/">Signin</Link>,
      key: 'signin',
      icon: auth?.user == null && <UserOutlined />,
    },
    {
      label: auth?.user !== null && (
        <span onClick={() => signOut()}>Sign out</span>
      ),
      key: 'signout',
      icon: auth?.user !== null && <LogoutOutlined />,
    },
    {
      label: <Link href="#">{auth?.user?.name || 'User'}</Link>,
      key: 'dashboard',
      icon: <SettingOutlined />,
      children: [
        {
          type: 'group',
          label: 'Management',
          children: [
            {
              label: <Link href={roleBasedLink()}>Dashboard</Link>,
              key: 'dashboard:1',
            },
          ],
        },
      ],
    },
    {
      label: <ConnectWallet />,
      key: 'connect',
      icon: <ApiOutlined />,
    },
  ];

  if (auth && auth.user !== null) {
    items.splice(2, 2);
  } else {
    items.splice(4, 2);
  }

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
      className={!auth?.user ? styles.topMenu : styles.topMenuAuth}
      theme="dark"
    />
  );
};
export default TopNav;
