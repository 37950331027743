import React from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { NewsItemProps } from './NewsItem.props';
import styles from './NewsItem.module.css';

const NewsItem = ({
  content,
  url,
  title,
  className,
  ...props
}: NewsItemProps) => {
  return (
    <div className={cn(styles.news, className)} {...props}>
      <Link href={url} className={styles.url}>
        <h3 className={styles.news_title}>{title}</h3>
      </Link>
      <p className={styles.content}>{content}</p>
    </div>
  );
};

export default NewsItem;
