import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/components/ConnectWallet/ConnectWallet.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/components/Footer/Footer.module.css");
;
import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/components/NewsItem/NewsItem.module.css");
;
import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/components/Sidebar/Sidebar.module.css");
;
import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/app/globals.css");
;
import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/assets/css/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/components/TopNav/TopNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/context/auth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletProvider"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/context/connectWallet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/marchcat/data/www/newscryptodefi.online/client/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/www/marchcat/data/www/newscryptodefi.online/client/node_modules/react-hot-toast/dist/index.mjs");
