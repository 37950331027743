'use client';
import React, { useContext } from 'react';
import { Button } from 'antd';
import { WalletContext } from '@app/context/connectWallet';

const isBrowser = typeof window !== 'undefined';

const ConnectWallet = () => {
  const [walletAddress, setWalletAddress] = useContext(WalletContext);

  const connectWallet = async () => {
    if (isBrowser) {
      const { solana } = window;
      if (solana) {
        const response = await solana.connect();
        console.log(
          'Connected with public key: ',
          response.publicKey.toString(),
        );
        setWalletAddress(response.publicKey.toString());
      }
    }
  };

  return (
    <>
      {!walletAddress ? (
        <Button onClick={connectWallet} type="primary">
          Connect Wallet
        </Button>
      ) : (
        <span>Connected...</span>
      )}
    </>
  );
};

export default ConnectWallet;
