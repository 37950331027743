'use client';
import React, { useState, useEffect, createContext, ReactNode } from 'react';
import axios from 'axios';

const AuthContext = createContext<any[]>([]);
const isBrowser = typeof window !== 'undefined';

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState<Record<string, unknown>>({
    user: null,
    token: '',
  });

  // config axios
  if (!isBrowser) {
    axios.defaults.baseURL = process.env.API;
    axios.defaults.headers.common['Authorization'] = `Bearer ${auth?.token}`;
  } else {
    axios.defaults.baseURL = process.env.NEXT_PUBLIC_API;
    axios.defaults.headers.common['Authorization'] = `Bearer ${auth?.token}`;
  }

  useEffect(() => {
    const authItem = localStorage.getItem('auth');
    if (authItem) {
      setAuth(JSON.parse(authItem));
    }
  }, []);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
